<template>
    <div class="boxed-navbar px-5">
        <div class="flex align-center cursor-pointer" @click="home">
            <v-avatar rounded="lg" size="43">
                <v-img
                    :src="asset('/images/logo/marketing-logo-apps.webp')"
                ></v-img>
            </v-avatar>
            <!-- v-if="$vuetify.display.width > 393" -->
            <div class="ml-3" v-if="$vuetify.display.width > 350">
                <div class="font-bold text-xl">Marketing</div>
            </div>
        </div>
        <!-- <v-toolbar-title>Vuetify</v-toolbar-title> -->
        <v-spacer></v-spacer>
        <!-- <v-slide-x-reverse-transition leave-absolute>
            <HorizontalMenu :menu="menu"></HorizontalMenu>
        </v-slide-x-reverse-transition> -->
        <v-spacer></v-spacer>
        <!-- <v-slide-y-reverse-transition>
            <v-btn
                icon
                rounded="full"
                variant="text"
                v-if="$vuetify.display.smAndDown"
            >
                <v-icon
                    class="icon-[ic--baseline-notifications-active]"
                ></v-icon>
            </v-btn>
        </v-slide-y-reverse-transition> -->
        <v-slide-y-reverse-transition>
            <v-btn
                icon
                rounded="full"
                variant="text"
                v-if="$vuetify.display.mdAndDown"
                @click="openDrawer"
            >
                <v-icon>
                    <span
                        :key="key"
                        v-if="drawer"
                        class="icon-[line-md--menu-to-close-alt-transition]"
                    ></span>
                    <span
                        :key="key"
                        v-if="!drawer"
                        class="icon-[line-md--close-to-menu-alt-transition]"
                    ></span>
                </v-icon>
            </v-btn>
        </v-slide-y-reverse-transition>

        <!-- <template v-if="$vuetify.display.smAndUp"> -->
        <!-- <ThemeSwitcher></ThemeSwitcher> -->
        <!-- </template> -->
        <!-- <template v-if="$can('read', 'auth')">
            <AppBarUserMenu></AppBarUserMenu>
        </template> -->
    </div>
</template>
<script setup>
// import menu from "@/menu.js";
import ThemeSwitcher from "./component/ThemeSwitcher.vue";
import HorizontalMenu from "./component/HorizontalMenu.vue";
import AppBarUserMenu from "./component/AppBarUserMenu.vue";
import { asset } from "@/mixins";
import { ref, watch } from "vue";

import { useDisplay } from "vuetify";
const { width } = useDisplay();

import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps(["drawer", "menu", "countMenu"]);
const emit = defineEmits(["emitDrawer", "emitHome"]);

function home() {
    emit("emitHome");
}

const openDrawer = () => {
    emit("emitDrawer");
    key.value += 1;
};

const key = ref(false);
watch(
    () => props.drawer,
    () => {
        key.value += 1;
    }
);
</script>
