// import { DateTime } from "luxon";

const filters = {
    formatMoney(value) {
        return new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
        }).format(value);
    },
    // toDate(value) {
    //     return DateTime.fromISO(value)
    //         .setLocale("id")
    //         .toLocaleString(DateTime.DATE_FULL);
    // },
    // toTime(value) {
    //     return DateTime.fromSQL(value)
    //         .setLocale("id")
    //         .toLocaleString(DateTime.TIME_24_SIMPLE);
    // },
    gender(value) {
        if (value == "L") {
            return "Laki-laki";
        } else {
            return "Perempuan";
        }
    },
};

// Vue.filter('fulldate', function(value){
//     return moment(value).format('dddd, DD MMMM YYYY')
// });

export default filters;
