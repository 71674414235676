export default [
    // {
    //     path: "/master-data/lecturers",
    //     name: "lecturers",
    //     component: () => import("@/pages/masterdata/lecturer/Lecturer.vue"),
    //     meta: {
    //         pageTitle: "Dosen",
    //         icon: "icon-[mdi--lecture]",
    //         layout: "LayoutFull",
    //         resource: "admin",
    //         action: "read",
    //     },
    // },
    {
        path: "/master-data/users",
        name: "users",
        component: () => import("@/pages/masterdata/users/User.vue"),
        meta: {
            pageTitle: "Pengguna",
            icon: "icon-[tabler--users]",
            layout: "LayoutFull",
            resource: "all",
            action: "read",
        },
    },
];
