import ability from "@/plugins/casl/ability";

export default [
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/pages/settings/Settings.vue"),
        meta: {
            pageTitle: "Pengaturan",
            icon: "icon-[ic--twotone-settings]",
            layout: "LayoutFull",
            resource: "auth",
            action: "read",
        },
        children: [
            {
                path: "account",
                name: "accountSetting",
                component: () => import("@/pages/settings/AccountDialog.vue"),
                meta: {
                    resource: "auth",
                    action: "read",
                    savedPosition: true,
                },
            },
            {
                path: "password",
                name: "passwordSetting",
                component: () => import("@/pages/settings/PasswordDialog.vue"),
                meta: {
                    resource: "auth",
                    action: "read",
                    savedPosition: true,
                },
            },
            // {
            //     path: "biodata",
            //     name: "biodataSetting",
            //     component: () => import("@/pages/settings/BiodataDialog.vue"),
            //     meta: {
            //         resource: "auth",
            //         action: "read",
            //         savedPosition: true,
            //     },
            //     beforeEnter: (to, from, next) => {
            //         const canNavigate = () => {
            //             return ability.can("read", "peserta");
            //         };
            //         if (!canNavigate()) {
            //             return next("/not-authorized");
            //         }
            //         next();
            //     },
            // },
        ],
    },
];
