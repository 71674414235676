import { createWebHistory, createRouter } from "vue-router";
import redirect from "./redirect";
import masterData from "./masterData";
import account from "./account";
import guest from "./guest";
import ability from "@/plugins/casl/ability";
import { nextTick } from "vue";

const routes = [
    ...redirect,
    ...masterData,
    ...account,
    ...guest,
    {
        path: "/home",
        name: "home",
        component: () => import("@/pages/home/Home.vue"),
        meta: {
            pageTitle: "Beranda",
            icon: "icon-[ic--twotone-home]",
            layout: "LayoutFull",
            resource: "auth",
            action: "read",
        },
    },
    {
        path: "/kerjasama",
        name: "kerjasama",
        component: () => import("@/pages/kerjasama/Kerjasama.vue"),
        meta: {
            pageTitle: "Pengajuan Kerjasama",
            icon: "icon-[tabler--file-description]",
            layout: "LayoutFull",
            resource: "admin",
            action: "read",
        },
    },
];

const router = createRouter({
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (from.meta.savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const canNavigate = to.matched.some((route) => {
        if (Array.isArray(route.meta.resource)) {
            let resource = route.meta.resource;
            const result = resource.some((r) => {
                return ability.can(route.meta.action || "read", r);
            });
            return result;
        } else {
            return ability.can(
                route.meta.action || "read",
                route.meta.resource
            );
        }
    });
    if (!canNavigate) {
        return next("/not-authorized");
    }
    next();
});

router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609

    nextTick().then(() => {
        document.title = to.meta.pageTitle + " - Marketing" || "Marketing";
    });
});

export default router;
