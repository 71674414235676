import { defineStore, acceptHMRUpdate } from "pinia";
import Api from "@/axios";
import { userLogin } from "@/plugins/casl/ability";
import { dateTime } from "@/mixins";

const defaultGroup = [
    { type: 4, group_id: 1 },
    { type: 5, group_id: 2 },
    { type: 6, group_id: 2 },
];

export const useAuthStore = defineStore("auth", {
    state: () => ({
        user: userLogin.data,
        detail: null,
        loading: false,
        stase: [],
        // isExpired: false,
        dateNow: "",
    }),
    getters: {
        isExpired: (state) => {
            let today = dateTime(state.dateNow);
            let staseDate = dateTime(state.stase[0]?.end_date);
            return staseDate.isBefore(today);
        },
        isLogin: (state) => (state.user != null ? true : false),
        isIos: () => {
            return (
                /iPad|iPhone|Macintosh|iPod/.test(navigator.userAgent) &&
                !window.MSStream
            );
        },
        //cek apakah student sudah mengatur jadwal shift
        studentShiftGroup: (state) => {
            if (state.user.type >= 4) {
                return state.user.student_detail.shift_group_id;
            } else {
                return 1;
            }
        },
        //cek apakah user memiliki jadwal default
        defaultShiftGroup: (state) =>
            defaultGroup.find((x) => x.type == state.user.type.id),
    },
    actions: {
        saveData(value) {
            if (value) {
                this.user = value;
            }
        },
        getDetail() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                Api.get("user-detail")
                    .then((res) => {
                        this.detail = res.data;
                        this.loading = false;
                        resolve(res);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },
        getStaseData() {
            if (this.user.student_detail) {
                return new Promise((resolve, reject) => {
                    Api.get("student-stase/" + this.user.student_detail.id)
                        .then((res) => {
                            this.stase = res.data.data;
                            // this.isExpired = res.data.isExpired;
                            this.dateNow = res.data.dateNow;
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            }
        },
        updateValueStase(value) {
            let index = this.stase
                .map((e) => {
                    return e.id;
                })
                .indexOf(value.id);
            this.stase.splice(index, 1, value);
        },
        addValueStase(value) {
            this.stase.unshift(value);
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
