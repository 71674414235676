import axios from "axios";
// import { toastError } from "./mixins";

const Api = axios.create({
    baseURL: window._asset + "api",
    timeout: 10000,
    timeoutErrorMessage: "Request timeout",
});

// Api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         if (error.message === "Request timeout") {
//             toastError(error.message);
//         }
//     }
// );

export default Api;
