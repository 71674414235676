// firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDSc7HI4W3XJwhEZHyh-wWcHqV3DCZYSvs",
    authDomain: "pemasaran-93bda.firebaseapp.com",
    projectId: "pemasaran-93bda",
    storageBucket: "pemasaran-93bda.appspot.com",
    messagingSenderId: "279907667396",
    appId: "1:279907667396:web:a383d230bb57d65413a891",
    measurementId: "G-JZCFSR95HD",
};

// Initialize Firebase
const fireApp = initializeApp(firebaseConfig);
const analytics = () => {
    getAnalytics(fireApp);
};
// const messaging = getMessaging(fireApp);

export { analytics };
